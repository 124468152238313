.video-chat .chat-box {
  position: relative;
  min-height: 250px;
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  min-width: 300px; }
  .video-chat .chat-box .leave-box {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    right: 0;
    bottom: 0; }
    .video-chat .chat-box .leave-box .leave-icon {
      cursor: pointer;
      margin-left: 5px; }
      .video-chat .chat-box .leave-box .leave-icon:hover {
        opacity: 0.8; }
      .video-chat .chat-box .leave-box .leave-icon img, .video-chat .chat-box .leave-box .leave-icon svg {
        max-width: 20px; }
      .video-chat .chat-box .leave-box .leave-icon svg {
        margin-right: 10px; }
  .video-chat .chat-box .main-status {
    text-align: center;
    font-weight: 600;
    color: #3f3f3f;
    font-size: 0.9em;
    margin-top: 10px; }
  .video-chat .chat-box .loader-box {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 20px; }
  .video-chat .chat-box .status-box {
    text-align: center;
    position: relative; }
    .video-chat .chat-box .status-box .progress {
      font-size: 1.5em;
      font-weight: 100; }
    .video-chat .chat-box .status-box .current-status {
      font-size: 0.8em;
      font-weight: 300; }
      .video-chat .chat-box .status-box .current-status span {
        font-weight: 500; }
      .video-chat .chat-box .status-box .current-status a {
        color: #3f3f3f;
        text-decoration: none;
        cursor: pointer; }
        .video-chat .chat-box .status-box .current-status a span {
          font-size: 0.85em; }
        .video-chat .chat-box .status-box .current-status a:hover {
          color: #ef8b21; }
  .video-chat .chat-box .chat-box-header {
    font-weight: 600;
    color: #3f3f3f;
    font-size: 0.9em;
    padding: 15px 10px;
    border-bottom: 1px solid #ebebeb; }
  .video-chat .chat-box .chat-box-content {
    padding: 10px 10px 5px; }
    .video-chat .chat-box .chat-box-content .chat-type {
      width: 100%;
      display: -webkit-flex;
      display: flex; }
    .video-chat .chat-box .chat-box-content .chat-msg-btn-send {
      background: #616D7A;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      border: 1px solid #a2b6cc;
      cursor: pointer;
      outline: none; }
      .video-chat .chat-box .chat-box-content .chat-msg-btn-send:hover {
        opacity: 0.9; }
      .video-chat .chat-box .chat-box-content .chat-msg-btn-send img {
        max-width: 15px;
        position: relative;
        left: 1px; }
    .video-chat .chat-box .chat-box-content .chat-msg-input {
      border: none;
      font-family: 'Poppins', sans-serif;
      color: #9c9c9c;
      outline: none;
      width: 100%; }
      .video-chat .chat-box .chat-box-content .chat-msg-input::-webkit-input-placeholder {
        font-family: 'Poppins', sans-serif;
        color: #c7c7c7; }
      .video-chat .chat-box .chat-box-content .chat-msg-input::-ms-input-placeholder {
        font-family: 'Poppins', sans-serif;
        color: #c7c7c7; }
      .video-chat .chat-box .chat-box-content .chat-msg-input::placeholder {
        font-family: 'Poppins', sans-serif;
        color: #c7c7c7; }
    .video-chat .chat-box .chat-box-content textarea {
      width: 96%;
      resize: none;
      outline: none;
      border: 1px solid #ebebeb;
      font-family: 'Poppins', sans-serif;
      color: #9c9c9c;
      padding: 0 5px; }
      .video-chat .chat-box .chat-box-content textarea::-webkit-input-placeholder {
        color: #c7c7c7; }
      .video-chat .chat-box .chat-box-content textarea::-ms-input-placeholder {
        color: #c7c7c7; }
      .video-chat .chat-box .chat-box-content textarea::placeholder {
        color: #c7c7c7; }
  .video-chat .chat-box .attachment-btn {
    background: #f1f1f1 !important;
    margin-right: 5px; }
    .video-chat .chat-box .attachment-btn img {
      position: relative;
      left: -0.5px !important; }
  .video-chat .chat-box .msg-file {
    text-decoration: none; }
    .video-chat .chat-box .msg-file:hover {
      opacity: 0.9; }
    .video-chat .chat-box .msg-file svg {
      max-width: 15px;
      max-height: 15px;
      position: relative;
      top: 5px;
      left: 25px; }
    .video-chat .chat-box .msg-file span {
      font-size: 0.6em;
      padding: 6px 14px 6px 34px;
      color: #000000; }
  .video-chat .chat-box .chat-container {
    padding: 10px;
    background: #F1F6F8;
    min-height: 52vh;
    overflow-y: scroll;
    max-height: 150px; }
    .video-chat .chat-box .chat-container span {
      border-radius: 3px; }
    .video-chat .chat-box .chat-container .normal-msg {
      margin-top: 10px; }
      .video-chat .chat-box .chat-container .normal-msg span {
        background: #fff;
        border: 1px solid #e7ecee; }
    .video-chat .chat-box .chat-container .self-msg {
      margin-top: 10px;
      color: white;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .video-chat .chat-box .chat-container .self-msg span {
        border: 1px solid #0192ff;
        background: #0083FF; }
    .video-chat .chat-box .chat-container .chat-msg {
      font-size: 0.8em;
      padding: 6px 14px; }

/*# sourceMappingURL=chat.css.map */

.app-panel-container {
  background: white;
  max-width: 1270px;
  width: 100%;
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17); }
  .app-panel-container .window-upper-beam {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 15px 20px; }
    .app-panel-container .window-upper-beam .close-window {
      margin-right: 10px;
      height: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding: 0px 5px;
      cursor: pointer; }
      .app-panel-container .window-upper-beam .close-window:hover {
        background: #ebebeb; }
      .app-panel-container .window-upper-beam .close-window svg {
        width: 8px;
        height: 8px; }
      .app-panel-container .window-upper-beam .close-window span {
        font-size: 0.6em;
        text-transform: uppercase;
        margin-left: 5px; }
    .app-panel-container .window-upper-beam .window-logo {
      text-transform: capitalize;
      font-weight: 800;
      font-size: 0.8em; }
      .app-panel-container .window-upper-beam .window-logo img {
        max-width: 100px; }
  .app-panel-container .panel-content-wrapper {
    padding: 0px 20px 15px;
    height: 80vh;
    overflow-y: scroll; }
    .app-panel-container .panel-content-wrapper .panel-table table {
      width: 100%; }
      .app-panel-container .panel-content-wrapper .panel-table table tr:nth-child(odd) {
        background: #f8f8f8; }
      .app-panel-container .panel-content-wrapper .panel-table table tr:first-child {
        background: none; }
      .app-panel-container .panel-content-wrapper .panel-table table tr:hover {
        background: #f4f4f4; }
      .app-panel-container .panel-content-wrapper .panel-table table th {
        font-size: 0.8em;
        font-weight: 500;
        color: #3f3f3f;
        border-bottom: 1px solid #efefef;
        padding: 10px; }
        .app-panel-container .panel-content-wrapper .panel-table table th:last-child {
          border-right: none; }
      .app-panel-container .panel-content-wrapper .panel-table table td {
        font-size: 0.8em;
        font-weight: 300;
        color: #3f3f3f;
        border-right: 1px solid #eaeaea;
        text-align: center;
        border-bottom: 1px solid #f4f4f4;
        padding: 5px 0px; }
        .app-panel-container .panel-content-wrapper .panel-table table td:last-child {
          border-right: none; }
      .app-panel-container .panel-content-wrapper .panel-table table .status {
        color: #fff;
        text-transform: uppercase;
        font-size: 0.6em;
        font-weight: 500; }
      .app-panel-container .panel-content-wrapper .panel-table table .patientRow {
        max-width: 10px; }

/*# sourceMappingURL=panel.css.map */

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  height: 100%; }

.pre-app {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#root:before {
  background-color: #5e6263;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0; }

.video-timer {
  background: red;
  position: absolute;
  right: 3px;
  padding: 2px 5px;
  top: 5px;
  font-size: 0.8em;
  color: #fff;
  width: 60px;
  text-align: center; }

.enter-room-wrapper {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 9;
  position: relative; }
  .enter-room-wrapper .enter-room-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background: white;
    padding: 30px 40px;
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17);
    border-radius: 5px; }
    .enter-room-wrapper .enter-room-container form, .enter-room-wrapper .enter-room-container .login-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      margin-top: 40px; }
      .enter-room-wrapper .enter-room-container form a, .enter-room-wrapper .enter-room-container .login-container a {
        color: #ef8b21;
        font-size: 0.7em; }
      .enter-room-wrapper .enter-room-container form label, .enter-room-wrapper .enter-room-container .login-container label {
        color: #3e444d;
        font-size: 0.6em; }
      .enter-room-wrapper .enter-room-container form input, .enter-room-wrapper .enter-room-container .login-container input {
        border: none;
        font-family: 'Poppins', sans-serif;
        color: #9c9c9c;
        outline: none;
        background: #f7f7f7;
        padding: 5px 10px 5px 10px;
        margin-bottom: 10px; }
      .enter-room-wrapper .enter-room-container form .ipt-small, .enter-room-wrapper .enter-room-container .login-container .ipt-small {
        font-size: 0.75em;
        min-width: 217px; }
      .enter-room-wrapper .enter-room-container form button, .enter-room-wrapper .enter-room-container .login-container button {
        border: none;
        background: #3e444d;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        cursor: pointer;
        padding: 10px 5px;
        margin-top: 20px;
        transition: all .2s; }
        .enter-room-wrapper .enter-room-container form button:hover, .enter-room-wrapper .enter-room-container .login-container button:hover {
          background: #ef8b21; }
      .enter-room-wrapper .enter-room-container form .btn-small, .enter-room-wrapper .enter-room-container .login-container .btn-small {
        padding: 5px;
        margin-top: 5px;
        background: #5c8584; }
    .enter-room-wrapper .enter-room-container img {
      max-width: 150px; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #a2b6cc;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }
.onscreen-notify {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999; }
  .onscreen-notify .app-screen-notify {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100vw;
    height: 100vh; }
  .onscreen-notify .asn-content {
    z-index: 9;
    display: block;
    position: relative;
    background: #fff;
    padding: 30px 50px; }
  .onscreen-notify .app-screen-bg {
    background: #000;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .onscreen-notify .asn-content {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 300px;
    height: 100%;
    max-height: 150px;
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17); }
    .onscreen-notify .asn-content svg {
      width: 20px;
      fill: #ff7184; }
    .onscreen-notify .asn-content .asc-info {
      margin-bottom: 10px;
      font-size: 0.9em;
      margin-top: 10px; }
    .onscreen-notify .asn-content a {
      border: none;
      color: white;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      cursor: pointer;
      transition: all .2s;
      font-size: 0.8em;
      text-decoration: none;
      padding: 5px 30px;
      margin-top: 5px;
      background: #5c8584; }
      .onscreen-notify .asn-content a:hover {
        background: #ef8b21; }

.notify-root {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9; }
  .notify-root .app-notify-container {
    padding: 25px 5px; }
    .notify-root .app-notify-container .app-notify {
      background: white;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      padding: 15px 25px;
      box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17);
      margin-bottom: 5px; }
      .notify-root .app-notify-container .app-notify .app-notify-icon {
        margin-right: 15px; }
        .notify-root .app-notify-container .app-notify .app-notify-icon svg {
          width: 25px;
          height: 25px; }
      .notify-root .app-notify-container .app-notify .app-notify-content {
        font-size: 0.8em; }
    .notify-root .app-notify-container .notify-success {
      background: #0055FF;
      box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17);
      color: #fff; }
      .notify-root .app-notify-container .notify-success svg {
        fill: #fff;
        position: relative;
        top: 2px; }
    .notify-root .app-notify-container .notify-error {
      background: #FF0251;
      box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17);
      color: #fff; }
      .notify-root .app-notify-container .notify-error svg {
        fill: #fff;
        position: relative;
        top: 2px; }

/*# sourceMappingURL=index.css.map */

.window-upper-beam {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px 20px; }
  .window-upper-beam .window-logo {
    text-transform: capitalize;
    font-weight: 800;
    font-size: 0.8em; }
    .window-upper-beam .window-logo img {
      max-width: 100px; }
  .window-upper-beam .window-details {
    position: relative;
    display: -webkit-flex;
    display: flex; }
    .window-upper-beam .window-details .window-langs {
      margin-left: 15px;
      display: -webkit-flex;
      display: flex; }
      .window-upper-beam .window-details .window-langs img {
        box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17); }
      .window-upper-beam .window-details .window-langs .lang {
        margin-right: 10px;
        cursor: pointer; }
        .window-upper-beam .window-details .window-langs .lang:hover {
          opacity: 0.8; }
    .window-upper-beam .window-details .patient-details {
      font-size: 0.7em;
      font-weight: 300;
      line-height: 1em; }
      .window-upper-beam .window-details .patient-details .email-trigger {
        bottom: 0;
        background: #ef8b21;
        color: white;
        padding: 5px 10px;
        font-size: 0.8em;
        text-transform: uppercase;
        cursor: pointer; }
        .window-upper-beam .window-details .patient-details .email-trigger:hover {
          opacity: 0.9; }
      .window-upper-beam .window-details .patient-details .patient-detail {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 5px; }
      .window-upper-beam .window-details .patient-details span {
        margin-left: 10px;
        text-transform: uppercase;
        color: #3f3f3f;
        font-weight: 500;
        text-align: right;
        width: 100%; }

.video-wrapper {
  background: #fff;
  width: 100%;
  max-width: 1370px;
  margin: 0 auto;
  position: relative;
  top: 20px;
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.17); }
  .video-wrapper .window-aligner {
    display: -webkit-flex;
    display: flex; }
  .video-wrapper .video-container {
    width: 100%; }
    .video-wrapper .video-container .local-video-wrapper {
      position: absolute;
      left: -21px;
      z-index: 9; }
      .video-wrapper .video-container .local-video-wrapper #localVideo {
        height: 112px; }
    .video-wrapper .video-container .video-controls {
      position: absolute;
      bottom: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%;
      padding: 50px 0;
      box-shadow: inset 0px -125px 62px -67px #3e444d; }
      .video-wrapper .video-container .video-controls .ctrl-options {
        border-left: 1px solid white;
        height: 30px;
        padding-left: 5px;
        margin-left: 5px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        position: relative;
        top: 15px; }
        .video-wrapper .video-container .video-controls .ctrl-options label {
          color: white;
          font-weight: 300;
          font-size: 0.7em;
          cursor: pointer; }
      .video-wrapper .video-container .video-controls .controls {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        position: relative;
        top: 40px; }
      .video-wrapper .video-container .video-controls .isRecording {
        background: #ff454a; }
        .video-wrapper .video-container .video-controls .isRecording svg {
          fill: #fff; }
        .video-wrapper .video-container .video-controls .isRecording:hover {
          background: #fff; }
          .video-wrapper .video-container .video-controls .isRecording:hover svg {
            fill: #000; }
      .video-wrapper .video-container .video-controls button {
        background: #fff;
        border: none;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        cursor: pointer;
        outline: none;
        margin: 5px; }
        .video-wrapper .video-container .video-controls button:disabled {
          opacity: 0.5;
          cursor: not-allowed; }
          .video-wrapper .video-container .video-controls button:disabled:active {
            opacity: 0.5; }
          .video-wrapper .video-container .video-controls button:disabled:hover .btn-icon img {
            max-width: 25px; }
        .video-wrapper .video-container .video-controls button:active {
          opacity: 0.8; }
        .video-wrapper .video-container .video-controls button:hover svg {
          fill: #ef8b21; }
        .video-wrapper .video-container .video-controls button .btn-icon {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center; }
          .video-wrapper .video-container .video-controls button .btn-icon img {
            max-width: 35px;
            transition: all .2s; }
          .video-wrapper .video-container .video-controls button .btn-icon svg {
            max-width: 35px;
            max-height: 50px;
            width: 25px; }
          .video-wrapper .video-container .video-controls button .btn-icon .rec-icon {
            max-width: 35px; }
      .video-wrapper .video-container .video-controls .end-btn {
        min-width: 200px;
        border-radius: 30px;
        background: #ff4a50;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.1em;
        height: 40px; }
    .video-wrapper .video-container .video-box {
      width: 100%;
      height: 100%;
      position: relative;
      background: #2a2e34; }
      .video-wrapper .video-container .video-box #remoteVideo {
        width: 100%; }

#remoteVideo.hide {
  display: none; }

.video-wrapper .status {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #868686;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 30vh;
  left: -280px;
  right: 0; }
  .video-wrapper .status p {
    margin: 0px;
    position: relative;
    top: -20px; }

@media screen and (max-width: 480px) {
  .video-wrapper {
    position: relative;
    max-width: 100vw; }

  #localVideo {
    height: 80px; }

  #remoteVideo {
    width: 100%;
    height: auto;
    margin-top: 20%; } }
@media screen and (orientation: landscape) {
  #localVideo {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 0px;
    margin-top: 0px; } }
@media screen and (min-width: 768px) {
  #localVideo {
    margin-left: 32px;
    margin-top: 12px; } }

/*# sourceMappingURL=video.css.map */

body,html{
  padding: 0;
  margin: 0;
}
